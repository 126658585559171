.App {
  text-align: center;
}

.logo {
  height: 200px;
  width: 330px;
  margin-bottom: 2rem;
  cursor: pointer;
}

.App-header {
  background-color: #FFF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333;
}

.App-link {
  color: #61dafb;
}

#webgl-viewer-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
}

#dropzoneFile {
  display: none;
}
.scale-up-center {
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.error-message {
  color: #C90C28;
}

.invisible {
  visibility: hidden;
}

#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 40px;
  height: 40px;
  border:  solid 4px transparent;
  border-top-color:  #B84F4B !important;
  border-left-color: #B84F4B !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
